/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'delete': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.83 3H15a1 1 0 110 2H3a1 1 0 010-2h3.17a3.001 3.001 0 015.66 0zm-1.098 0a2 2 0 00-3.464 0h3.464zM15 8H3l1.103 10.108a1 1 0 00.994.892h7.806a1 1 0 00.994-.892L15 8zm-9.403 2.049a.5.5 0 00-.445.55l.523 4.972a.5.5 0 00.994-.105l-.522-4.972a.5.5 0 00-.55-.445zM8.5 10.5a.5.5 0 011 0v5a.5.5 0 01-1 0v-5zm3.897-.451a.5.5 0 00-.55.445l-.522 4.972a.5.5 0 10.995.105l.522-4.973a.5.5 0 00-.445-.55z" _fill="#898B9B"/>'
  }
})
